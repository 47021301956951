// TODO: add pluralized?
// The `vacancy` argument is added for compatibility with the original useVacancyTypeLabel, but we're not using it on Stagedoos (yet, at least)
export default function useVacancyTypeLabel(vacancy?: Vacancy) {
  const { t: $t } = useI18n({ useScope: 'global' })

  const isMas = useIsMas()
  const vacancyType = isMas ? 'mas' : 'traineeship'

  const vacancyTypeLabel = computed(() =>
    $t(
      `vacancy.attributes.vacancyTypeLabel.${vacancyType}.singular`,
    ),
  )

  const vacancyTypeLabelPlural = computed(() =>
    $t(
      `vacancy.attributes.vacancyTypeLabel.${vacancyType}.plural`,
    ),
  )

  const vacancyTypeLabelCapitalized = computed(() =>
    capitalize(vacancyTypeLabel.value),
  )

  return {
    vacancyTypeLabel: vacancyTypeLabel.value,
    vacancyTypeLabelCapitalized: vacancyTypeLabelCapitalized.value,
    vacancyTypeLabelPlural: vacancyTypeLabelPlural.value,
  }
}
